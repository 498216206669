import { createWebHistory, createRouter } from "vue-router";
import Home from '@/components/Home.vue';
import Contact from '@/components/Contact.vue';
import Blog from '@/components/Blog.vue';
import About from '@/components/About.vue';
import DataProtection from '@/components/DataProtection.vue';
import Imprint from '@/components/Imprint.vue';


const routes = [
    { path: '', component: Home },
    { path: '/contact', component: Contact },
    { path: '/blog', component: Blog },
    { path: '/about', component: About },
    { path: '/data-protection', component: DataProtection },
    { path: '/imprint', component: Imprint }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;