<template>
  <div class="custom_header">
    <!-- menu bar with header and social media buttons -->

    <header
      v-on:scroll.passive="handleScroll"
      v-bind:style="{ backgroundColor: headerColor }"
    >
      <!-- menu bar with header -->
      <b-nav class="navbar navbar-expand-sm navbar-light bg-faded flex-nowrap">
        <!-- Header for mobile view - the other header disappears due to 'collapse' -->
        <!-- <div class="d-md-none"></div> -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="handleClick"
        >
          <!-- <span class="navbar-toggler-icon"></span> -->
          <!-- <span class="fa fa-bars"></span> -->
          <img class="mobile_img fixed-top" v-bind:src="symbol" />
        </button>

        <div
          class="navbar-collapse navbar-toggleable-xs collapse w-100 justify-content-center"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav mx-auto {change_color: scrollPosition > 50}">
            <!-- left horizontal menu bar -->
            <li
              class="nav-item"
              v-bind:key="item"
              v-for="(item, index) in menu_left"
            >
              <!-- <a class="nav-link" v-bind:href="left_menu_links[index]">{{ item }}</a> -->
              <router-link :to="left_menu_links[index]" @click="$scrollToTop">{{
                item
              }}</router-link>
            </li>
            <!-- Headers -->
            <li class="nav-item d-none d-md-block">
              <!-- <h1>{{ title1 }}</h1>
                <h1>{{ title2 }}</h1> -->
              <img v-bind:src="symbol" />
              <h2>{{ subtitle }}</h2>
            </li>
            <!-- right horizontal menu bar -->
            <li
              class="nav-item"
              v-bind:key="item"
              v-for="(item, index) in menu_right"
            >
              <!-- <a class="nav-link" v-bind:href="right_menu_links[index]">{{ item }}</a> -->
              <router-link
                :to="right_menu_links[index]"
                @click="$scrollToTop"
                >{{ item }}</router-link
              >
            </li>
          </ul>
        </div>
      </b-nav>

      <!-- social media symbols -->
      <div class="d-none d-md-block">
        <div class="social row">
          <div class="col-ms-2">
            <a v-bind:href="facebook"><i class="fa fa-facebook fa-xs"></i></a>
          </div>
          <div class="col-ms-2">
            <a v-bind:href="instagram"><i class="fa fa-instagram fa-xs"></i></a>
          </div>
          <div class="col-ms-2">
            <a v-bind:href="etsy"><i class="fa fa-etsy fa-xs"></i></a>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      homepage_title: "Manuela Fichtner - Vergolderei",
      title1: "Manuela",
      title2: "Fichtner",
      subtitle: "Die Vergolderei",
      left_menu_links: ["/", "/about"],
      menu_left: ["Home", "About"],
      right_menu_links: ["/blog", "/contact"],
      menu_right: ["Blog", "Contact"],
      headerColor: "transparent",
      facebook: "https://www.facebook.com/DieVergolderey/",
      instagram: "https://www.instagram.com/die.vergolderei/",
      etsy: "https://www.etsy.com/de/shop/DieVergolderei",
      symbol: require("../../../images/products/Sonstiges/Logo2.png"),
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleClick() {
      if (this.headerColor === "white") {
        this.headerColor = "transparent";
      } else {
        this.headerColor = "white";
      }
    },
    handleScroll() {
      if (window.scrollY > 0) {
        this.headerColor = "white";
      } else {
        this.headerColor = "transparent";
      }
    },
  },
  created: function() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted: function() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-family: "Yellowtail", serif;
  transform: rotate(340deg);
  font-size: 48px;
  text-align: center;
  margin-bottom: 25px;
  margin-top: -30px;
}

h2 {
  font-family: "Amatic SC", cursive;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}

a:link,
a:hover,
a:visited {
  font-family: "Amatic SC", cursive;
  /* font-size: 30px; */
  font-size: 5vh;
  color: #000;
}

ul {
  list-style-type: none;
}

li {
  padding: 15vh 5vw 0.5vh;
  line-height: 0.01vh;
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: "transparent";
  z-index: 1;
}

.social {
  position: fixed;
  top: 20px;
}

.fa {
  font-size: 1rem;
  padding: 10px;
  margin-left: 40px;
}

.nav-item {
  font-weight: bold;
  color: black;
}

img {
  /* margin-top: -50px; */
  width: 150px;
  margin-top: -10vh;
  /* width: 8vw; */
  height: auto;
  text-align: center;
}

button {
  float: right;
  margin-left: 25%;
  margin-top: 15%;
  border: None;
}

button:focus {
  outline: none;
}

.mobile_img {
  width: 25%;
  margin-left: 35vw;
  margin-top: 5vh;
}

@media (max-width: 400px) {
  /* a:link,
  a:hover,
  a:visited {
    font-size: 3vh;
  } */
  /* .nav-item {
    margin-top: -10vh;
    text-align: center;
    margin-left: -40vw;
  } */
  ul {
    margin-top: 17vh;
    margin-bottom: 15vh;
  }
}
</style>
