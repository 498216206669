<template>
  <div class="page_home">
    <div id="app">
      <!-- menu bar with header and social media buttons -->
      <Header></Header>

      <img />

      <!-- Diashow -->
      <ul class="cb-slideshow">
        <li v-bind:key="image_title" v-for="image_title in diashow">
          <span>{{ image_title }}</span>
        </li>
      </ul>

      <!-- About Me -->
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg centered-image">
            <img v-bind:src="manu_image" />
          </div>
          <div class="col-lg"  @click="toggleFullscreen">
            <h3>
              Willkommen <br />
              in der Vergolderei Fichtner
            </h3>
            <p>
              {{ AboutMeText }}
            </p>
            <p>
              Wenn du Interesse an meinen Arbeiten hast, dann melde dich gerne
              bei mir. Zusammen finden wir genau das Richtige für dich und dein
              Zuhause.
            </p>
            <p>
              Schau auch mal in meinem
              <a href="https://www.etsy.com/de/shop/DieVergolderei"
                >Etsy Shop</a
              >
              vorbei oder
            </p>
            <button
              class="meetme_button centered-image"
              @click="$router.push('contact')"
            >
              {{ AboutMeButtonText }}
            </button>
          </div>
        </div>
      </div>

      <!-- Blog -->
      <!-- Testimonials -->

      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./general/Header";
import Footer from "./general/Footer";

export default {
  name: "Home",
  data() {
    return {
      AboutMeText:
        "Die Verarbeitung von Blattmetallen greift auf eine bereits 4000 Jahre lange Geschichte zurück. Der Beruf des Vergolders und Fassmalers existiert seit dem Mittelalter und beschränkt sich nicht nur auf den Vorgang des Vergoldens als Solches. Vor allem die Gestaltung mit verschiedensten Verziertechniken und das Fassen von Skulpturen, etc. macht diesen Beruf so spannend. Auch die Malerei gehört zum Berufsbild. Sowohl die Neugestaltung als auch die Rekonstruktion historischer Vorbilder. Der Vorgang des Vergoldens mit Blattmetallen ist sehr komplex und vielschichtig. Überwiegend wird mit edelstem 23,75 karätigem Blattgold gearbeitet. Aber auch Blattmetalle wie Blattsilber, Blattplatin und viele Legierungen können verarbeitet werden.",
      AboutMeButtonText: "Sag Hallo",
      diashow: [1, 2, 3, 4, 5, 6],
      manu_image: require("../../images/about/manu.jpg"),
    };
  },
  created() {
    AOS.init();
  },
  unmounted() {
    AOS.refresh();
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  font-family: "Gaegu", cursive;
  font-size: 40px;
  text-align: center;
}

ul {
  list-style-type: none;
}

img {
  width: 80%;
  height: 90%;
}

.cb-slideshow,
.cb-slideshow:after {
  display: block;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
}

.cb-slideshow li span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: none;
  opacity: 0;
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -webkit-animation: imageAnimation 36s linear infinite 0s;
  -moz-animation: imageAnimation 36s linear infinite 0s;
  -o-animation: imageAnimation 36s linear infinite 0s;
  -ms-animation: imageAnimation 36s linear infinite 0s;
  animation: imageAnimation 36s linear infinite 0s;
}

.cb-slideshow li:nth-child(1) span {
  background-image: url(../../images/diashow/downsized/1.jpg);
}
.cb-slideshow li:nth-child(2) span {
  background-image: url(../../images/diashow/downsized/2.jpg);
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  -ms-animation-delay: 6s;
  animation-delay: 6s;
}
.cb-slideshow li:nth-child(3) span {
  background-image: url(../../images/diashow/downsized/3.jpg);
  -webkit-animation-delay: 12s;
  -moz-animation-delay: 12s;
  -o-animation-delay: 12s;
  -ms-animation-delay: 12s;
  animation-delay: 12s;
}
.cb-slideshow li:nth-child(4) span {
  background-image: url(../../images/diashow/downsized/4.jpg);
  -webkit-animation-delay: 18s;
  -moz-animation-delay: 18s;
  -o-animation-delay: 18s;
  -ms-animation-delay: 18s;
  animation-delay: 18s;
}
.cb-slideshow li:nth-child(5) span {
  background-image: url(../../images/diashow/downsized/5.jpg);
  -webkit-animation-delay: 24s;
  -moz-animation-delay: 24s;
  -o-animation-delay: 24s;
  -ms-animation-delay: 24s;
  animation-delay: 24s;
}
.cb-slideshow li:nth-child(6) span {
  background-image: url(../../images/diashow/downsized/6.jpg);
  -webkit-animation-delay: 30s;
  -moz-animation-delay: 30s;
  -o-animation-delay: 30s;
  -ms-animation-delay: 30s;
  animation-delay: 30s;
}
.cb-slideshow li:nth-child(2) span {
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  -ms-animation-delay: 6s;
  animation-delay: 6s;
}
.cb-slideshow li:nth-child(3) span {
  -webkit-animation-delay: 12s;
  -moz-animation-delay: 12s;
  -o-animation-delay: 12s;
  -ms-animation-delay: 12s;
  animation-delay: 12s;
}
.cb-slideshow li:nth-child(4) span {
  -webkit-animation-delay: 18s;
  -moz-animation-delay: 18s;
  -o-animation-delay: 18s;
  -ms-animation-delay: 18s;
  animation-delay: 18s;
}
.cb-slideshow li:nth-child(5) span {
  -webkit-animation-delay: 24s;
  -moz-animation-delay: 24s;
  -o-animation-delay: 24s;
  -ms-animation-delay: 24s;
  animation-delay: 24s;
}
.cb-slideshow li:nth-child(6) span {
  -webkit-animation-delay: 30s;
  -moz-animation-delay: 30s;
  -o-animation-delay: 30s;
  -ms-animation-delay: 30s;
  animation-delay: 30s;
}

/* Animation for the slideshow images */
@-webkit-keyframes imageAnimation {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes imageAnimation {
  0% {
    opacity: 0;
    -moz-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -moz-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes imageAnimation {
  0% {
    opacity: 0;
    -o-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -o-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes imageAnimation {
  0% {
    opacity: 0;
    -ms-animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    -ms-animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.container {
  /* margin-top: 60% vs 200%; */
  margin-top: 120vh;
  background-color: transparent;
  position: relative;
}

.meetme_button {
  border: none;
  font-family: "Amatic SC", cursive;
  font-size: 20px;
  background: rgb(248, 242, 242);
  padding: 12px 20px;
  color: goldenrod;
  font-weight: bold;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
}

.centered-image {
  text-align: center;
}
</style>
