<template>
  <div class="page_about">
    <Header></Header>
    <div class="empty_top"></div>
    <!-- About Me -->
    <div class="container">
      <div class="row justify-content-center">
        <h3>Über mich</h3>
      </div>
      <div class="row">
        <p>
          Ich begann meine berufliche Laufbahn 2007, nach meinem Fachabitur, mit
          der Ausbildung zur Vergolderin/Fassmalerin in der
          Restaurierungswerkstätte Lederer&Bannach. 2010 durfte ich diese mit
          Auszeichnung beenden. Anschließend war ich bei verschiedenen Firmen
          tätig, darunter eine Kirchenmalerfirma und ein Rahmenvergolderbetrieb.
          Dadurch konnte ich viel Erfahrung auf den unterschiedlichsten Gebieten
          sammeln. 2012/2013 besuchte ich die Meisterschule und absolvierte die
          Meisterprüfung für Vergolder mit Auszeichnung.
        </p>
      </div>
      <div class="row">
        <img v-bind:src="ProfileImage" />
      </div>
      <div class="row">
        <p>
          Als 2018 unser Sohn zur Welt kam, habe ich mich nach meiner Elternzeit
          beruflich verändert und begann mit meiner Selbstständigkeit.<br />
          Seitdem habe ich im Bereich der Restaurierung bei vielen Projekten
          mitgewirkt. Diese umfassten sowohl Raumausstattung als auch Arbeiten
          an Raumschale und Fassaden.<br />
          Mein Tätigkeitsbereich beschränkt sich aber nicht nur auf die
          Restaurierung, sondern umfasst auch die Neuvergoldung, Fassmalerei,
          Wandgestaltung, Anfertigung von Deko-Objekten und die Rekonstruktion
          historischer Gegenstände.
        </p>
        <p>
          Der Beruf des Vergolders/Fassmalers liegt mir sehr am Herzen. Für mich
          persönlich ist es nicht nur ein Beruf, sondern eine Leidenschaft.
          Daher versuche ich mehr und mehr mich innerhalb der Ausbildung zu
          engagieren. Zudem gebe ich immer wieder kleine Vergolderkurse, um den
          Menschen diesen wundervollen Beruf näher zu bringen.
        </p>
        <hr />
        <!-- <blockquote>
            <p class="quote">{{ Quote }}></p>
          </blockquote> -->
        <button class="meetme_button" @click="$router.push('contact')">
          {{ ButtonText }}
        </button>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./general/Header";
import Footer from "./general/Footer";

export default {
  name: "About",
  data() {
    return {
      Quote: "Gold up your Life",
      ProfileImage: require("../../images/about/manu2.jpg"),
      ButtonText: "Sag Hallo",
    };
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
.empty_top {
  background-color: white;
  top: 0;
  width: 100%;
  height: 30vh;
}
.meetme_button {
  border: none;
  font-family: "Amatic SC", cursive;
  font-size: 20px;
  background: rgb(248, 242, 242);
  /* padding: 12px 20px; */
  width: 100%;
  color: goldenrod;
  font-weight: bold;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
}
img {
  /* width: 546px;
  height: 364px; */
  width: 100%;
  height: 100%;
}
h3 {
  font-family: "Gaegu", cursive;
  font-size: 40px;
  background-color: rgb(248, 242, 242);
  text-align: center;
}
.quote {
  font-family: "Yellowtail", serif;
  font-size: 20px;
}
blockquote p {
  quotes: "»" "«";
}
.container {
  margin-top: 5%;
}

.row {
  margin-bottom: 5%;
  width: 98%;
  margin-left: 1%;
}
</style>
