<template>
  <div class="page_imprint">
    <Header></Header>
    <div class="container">
      <div class="empty_top"></div>
      <h1>Impressum</h1>

      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>
        Manuela Fichtner<br />
        Manuela Fichtner - Vergolderei<br />
        <br />
        <br />
        Deutschland<br />
        Liebfrauenweg 8<br />
        93336 Altmannstein
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: 017630584367<br />
        Telefax: -<br />
        E-Mail: info@vergolderei-fichtner.de
      </p>

      <h2>EU-Streitschlichtung</h2>
      <p>
        Die Europ&auml;ische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:
        <a
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noopener noreferrer"
          >https://ec.europa.eu/consumers/odr/</a
        >.<br />
        Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>

      <h2>
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
      </h2>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./general/Header";
import Footer from "./general/Footer";

export default {
  name: "Imprint",
  data() {
    return {
      ImprintHeader: "Impressum",
      Name: "Die Vergolderei – Manuela Fichtner",
      Street: "Liebfrauenweg 8",
      City: "93336 Altmannstein",
      Email: "E-Mail: Manuela_Fichtner@gmx.de",
      Telephone: "Tel: +49 176 30584367",
    };
  },
  methods: {},
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
.empty_top {
  background-color: white;
  top: 0;
  width: 100%;
  height: 205px;
}
h2 {
  font-family: "Amatic SC", cursive;
  margin-top: 100px;
  font-weight: bold;
}
</style>
