<template>
  <div class="custom_footer">
    <br />
    <!-- social media symbols -->
    <div class="d-md-none">
      <div class="social row">
        <div class="col-ms-2">
          <a v-bind:href="facebook"><i class="fa fa-facebook fa-xs"></i></a>
        </div>
        <div class="col-ms-2">
          <a v-bind:href="instagram"><i class="fa fa-instagram fa-xs"></i></a>
        </div>
        <div class="col-ms-2">
          <a v-bind:href="etsy"><i class="fa fa-etsy fa-xs"></i></a>
        </div>
      </div>
    </div>
    <br />
    <p>
      <button class="footer_button" @click="$router.push('data-protection')">
        Datenschutz
      </button>
      <button class="footer_button" @click="$router.push('imprint')">
        Impressum
      </button>
      <a class="footer_button" href="#uc-corner-modal-show"
        >Cookie-Einstellungen</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.custom_footer {
  margin-top: 5%;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 180px;
  background-color: rgb(248, 242, 242);
  color: black;
  text-align: center;
  text-shadow: 1px 1px rgb(202, 198, 198);
  word-spacing: 2%;
}

.footer_button {
  font-family: "Amatic SC", cursive;
  font-size: 150%;
  border: none;
  background: rgb(248, 242, 242);
  padding: 0% 10%;
  color: black;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
}

a:link {
  text-decoration: none;
  text-shadow: none;
  width: 100%;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.fa {
  font-size: 1rem;
  padding: 10px;
  margin-left: 70px;
}
</style>
