<template>
  <div class="page_blog">
    <Header></Header>
    <div class="empty_top"></div>
    <div class="container">
      <!-- Slider -->
      <div id="app">
        <div class="title-container">
          <div>
            <h3 class="title">
              {{ OfferTitle }}
            </h3>
          </div>
          <div class="filters">
            <span
              class="filter"
              v-bind:class="{ active: currentFilter === Segment }"
              v-on:click="setFilter(Segment)"
              :key="Segment"
              v-for="Segment in BasicSegmentation"
              >{{ Segment }}</span
            >
          </div>
        </div>

        <div class="row justify-content-center gallery_container">
          <transition-group class="projects" name="projects">
            <div
              class="project"
              v-bind:key="project.title"
              v-for="project in SelectedProjects"
              @click="showSocial(project)"
              v-bind:class="{ active: project.showMe }"
            >
              <div class="project-image-wrapper">
                <img class="project-image" v-bind:src="project.image" />
                <div class="gradient-overlay"></div>
                <div class="circle">
                  <span class="project-title">{{ project.title }}</span>
                </div>
              </div>
            </div>
          </transition-group>
        </div>

        <!-- <div class="imagegallery">
          <h4>{{ currentTitle }}</h4>
        </div> -->
        <div class="scrollstart">
          <h4>{{ currentTitle }}</h4>
          <br />
          <p>{{ currentDescription }}</p>
        </div>

        <div class="imagegallery" v-if="projects[0].showMe">
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Restaurierung/Restaurierung Emaille.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Restaurierung eines Brauerei Emaille Schilds. Rechts unten ist
                  das Ergebnis.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Restaurierung/Restaurierung vorher2.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Truhe vor der Restaurierung
                </div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Restaurierung/Restaurierung vorher3.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Truhe vor der Restaurierung
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Restaurierung/Restaurierung nachher.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Truhe nach der Restaurierung
                </div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Restaurierung/Restaurierung nachher2.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Truhe nach der Restaurierung
                </div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Restaurierung/Restaurierung nachher3.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Truhe nach der Restaurierung
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Restaurierung/Restaurierung vorher.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Designer-Hocker vor der Restaurierung
                </div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Restaurierung/Restaurierung nachher4.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Designer-Hocker nach der Restaurierung
                </div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Restaurierung/Restaurierung nachher5.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Designer-Hocker nach der Restaurierung
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Restaurierung/Restaurierung vorher4.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Rahmen vor der Restaurierung
                </div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Restaurierung/Restaurierung nachher6.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Rahmen nach der Restaurierung
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Restaurierung/Gemälderestaurierung.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Gemälderestaurierung
                </div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Restaurierung/Skulptur Restaurierung.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Restaurierung einer Skulptur
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="imagegallery" v-if="projects[1].showMe">
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Wandgestaltung/Brokatmalerei.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Brokatmalerei</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Wandgestaltung/Malerei.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Malerei</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Wandgestaltung/Marmorierung.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Marmorierung</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Wandgestaltung/Pinselschrift.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Pinselschrift</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Wandgestaltung/Wandgestaltung.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Wandgestaltung</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Wandgestaltung/Wandgestaltung Goldleiste Marmorierung.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Marmorierung mit goldener Wandleiste</div>
              </div>
            </div>
          </div>
        </div>

        <div class="imagegallery" v-if="projects[2].showMe">
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Fassmalerei/Gesellenstück_Fassmalerei.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Fassmalerei - Hier mein Gesellenstück</div>
              </div>
            </div>
          </div>
        </div>

        <div class="imagegallery" v-if="projects[3].showMe">
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Vergoldung/Aufbau_Polimentvergoldung.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Aufbau einer Poliment Vergoldung</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Vergoldung/Blattgold.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Blattgold</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Vergoldung/Ölvergoldung.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Ölvergoldung</div>
              </div>
            </div>
          </div>
        </div>

        <div class="imagegallery" v-if="projects[4].showMe">
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Schmuck/Anhaenger_Beton.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Halskette 'Betongold'</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Schmuck/Ohrringe.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Ohrringe <br />
                  'Goldene Welt'
                </div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Schmuck/Schmuckdosen.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Schmuckdosen</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Schmuck/Schmuckkollektion.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Schmuck-Kollektion</div>
              </div>
            </div>
          </div>
        </div>

        <div class="imagegallery" v-if="projects[5].showMe">
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Kids/Kids_Kollektion.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Kids-Kollektion</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Kids/Kindergarderobe2.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Kindergarderobe</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Kids/Kindergarderobe.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Kindergarderobe
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Kids/Lampe Regenbogen.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Regenbogen-Lampe</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Kids/Lampe Wolke.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Wolken-Lampe</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Kids/Lampe Muschel.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Muschel-Lampe</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Kids/Zahndöschen.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Zahndöschen</div>
              </div>
            </div>
          </div>
        </div>

        <div class="imagegallery" v-if="projects[6].showMe">
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Betondeko.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Deko-Kollektion 'Beton'</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Betondeko2.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Deko-Kollektion 'Beton'</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Garderobe1.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Garderobe</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Garderoben.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Garderoben</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Kerzenständer.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Kerzenständer</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Tischlampe.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Tischlampe</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Uhren.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Uhren</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Magnetrahmen.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Magnetrahmen</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Home/Magnettafel Magnetbuchstaben.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Magnettafel mit Magnetbuchstaben</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Rahmen.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Bilderrahmen</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Rahmen2.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Bilderrahmen</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Rahmen3.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Bilderrahmen</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Rahmen4.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Bilderrahmen</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Schale.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Holzschale mit goldener Innenfläche</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Schalen.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Holzschalen mit goldener Innenfläche</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Osterdeko.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Osterdeko</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="require('../../images/products/Home/Weihnachtsdeko.jpg')"
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Weihnachtsdeko</div>
              </div>
            </div>
          </div>
        </div>

        <div class="imagegallery" v-if="projects[7].showMe">
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Rekonstruktion/Rekonstruktion original.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Historisches Original einer Schatulle</div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Rekonstruktion/Rekonstruktion Original.jpeg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Historisches Original einer Schatulle</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Rekonstruktion/Rekonstruktion.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Rekonstruktion einer historischen Schatulle
                </div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Rekonstruktion/Rekonstruktion2.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Rekonstruktion einer historischen Schatulle
                </div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Rekonstruktion/Rekonstruktion3.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Rekonstruktion einer historischen Schatulle
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Rekonstruktion/Rekonstruktion original 2.jpeg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Historisches Original eines Bilderrahmens
                </div>
              </div>
            </div>
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Rekonstruktion/Rekonstruktion ornamentik.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">
                  Rekonstruktion eines historischen Bilderrahmens
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg" @click="toggleFullscreen">
              <img
                :src="
                  require('../../images/products/Rekonstruktion/Rekonstruktionsmalerei.jpg')
                "
                class="img-fluid"
                alt=""
              />
              <div class="overlay">
                <div class="text">Rekonstruktionsmalerei</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./general/Header";
import Footer from "./general/Footer";

export default {
  name: "Blog",
  data() {
    return {
      currentFilter: "ALLE",
      OfferTitle: "Mein Angebot",
      BasicSegmentation: ["ALLE", "PRODUKTE", "DIENSTLEISTUNGEN"],
      currentTitle: "",
      currentDescription: "",
      projects: [
        {
          title: "Restaurierung",
          description:
            "In diesem Bereich biete ich sowohl die Konservierung als auch Restaurierung von allen gefassten Oberflächen, Gemälden, Kunstobjekten, usw. an.",
          image: require("../../images/products/Restaurierung/Restaurierung vorher4.jpg"),
          category: "DIENSTLEISTUNGEN",
          showMe: false,
        },
        {
          title: "Wandgestaltung",
          description:
            "Im Bereich der Wandgestaltung biete ich die Gestaltung mit Farbe, wie z.b. Marmorimitation, kleine Malereien, Pinselschrift oder die Gestaltung mit Blattmetallen an.",
          image: require("../../images/products/Wandgestaltung/Wandgestaltung Goldleiste Marmorierung.jpg"),
          category: "DIENSTLEISTUNGEN",
          showMe: false,
        },
        {
          title: "Fassmalerei",
          description:
            "Im Bereich der Fassmalerei biete ich die Neufassung verschiedenster Objekte wie z.B Skulpturen, Rahmen, Mobiliar, etc. Dazu zählen u.a. auch Marmor- oder Holzimitation.",
          image: require("../../images/products/Fassmalerei/Gesellenstück_Fassmalerei.jpg"),
          category: "DIENSTLEISTUNGEN",
          showMe: false,
        },
        {
          title: "Vergoldung",
          description:
            "Im Bereich der Neuvergoldungen biete ich u.a. Polimentglanz– und Mattvergoldungen auf Holz, Gips, Beton, etc. sowie die dazugehörigen Vergoldetechniken wie Radierung, Gravur, Lüster, Aufsetzarbeit, usw. Ölvergoldungen sind auf fast allen Untergründen und Metallisierungen mit den unterschiedlichsten Blattmetallen (auch Kupfer, Messing, Aluminium) möglich.",
          image: require("../../images/products/Vergoldung/Blattgold.jpg"),
          category: "DIENSTLEISTUNGEN",
          showMe: false,
        },
        {
          title: "Schmuck",
          description:
            "Ich fertige Betonschmuck mit Blattgold an, darunter Schmuckanhänger, Ohrringe und auch Armbänder. Gerne gestalte ich den Schmuck individuell nach Kundenwunsch. Außerdem biete ich passende Schmuckboxen an, welche als Geschenkverpackung oder auch als Ringboxen für Hochzeiten verwendet werden können.",
          image: require("../../images/products/Schmuck/Anhaenger_Beton.jpg"),
          category: "PRODUKTE",
          showMe: false,
        },
        {
          title: "Kids",
          description:
            "Ich fertige für Kinder und das Kinderzimmer Garderoben, Wandleuchten, Hängelampen, Kreidetafeln, Magnettafel/-buchstaben, Kosmetiktuchboxen und Zahndöschen an. Auch hier gilt wieder, dass ich mich nach Kundenwünschen richte, insbesondere bei der Farbgestaltung.",
          image: require("../../images/products/Kids/Kids_Kollektion.jpg"),
          category: "PRODUKTE",
          showMe: false,
        },
        {
          title: "Home",
          description:
            "Ich fertige verschiedenste Dekorationsobjekte und Mobiliar aus Holz oder Beton mit Blattmetallen an, darunter Wanduhren, Garderoben, Hängelampen, Tischlampen, Schalen, Etageren, Untersetzer, Rahmen. Auch hier gehe ich gerne auf Kundenwünsche ein. Alles was ich herstelle, kann individuell gestaltet werden.",
          image: require("../../images/products/Home/Tischlampe.jpg"),
          category: "PRODUKTE",
          showMe: false,
        },
        {
          title: "Historisch",
          description:
            "In diesem Bereich biete ich die Rekonstruktion verschiedener gefasster und/oder vergoldeter Objekte.",
          image: require("../../images/products/Rekonstruktion/Rekonstruktion2.jpg"),
          category: "PRODUKTE",
          showMe: false,
        },
      ],
    };
  },
  methods: {
    toggleFullscreen: function(event) {
      var element = event.target.parentElement.children[0];
      if (document.fullscreenElement) { 
        return document.exitFullscreen() // exit fullscreen on next click
      }
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (this.element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen() // Safari
      } else if (this.element.msRequestFullscreen) {
        element.msRequestFullscreen() // IE11
      }
    },
    setFilter: function(filter) {
      this.currentFilter = filter;
    },
    showSocial: function(project) {
      // reset all projects to false
      var i;
      for (i = 0; i < this.projects.length; i++) {
        this.projects[i].showMe = false;
      }
      // set wanted showMe to true
      project.showMe = true;

      // set current title for header
      this.currentTitle = project.title;

      // set current description under header
      this.currentDescription = project.description;

      // scroll down
      this.scrollToElement(
        { behavior: "smooth", block: "center" },
        "scrollstart"
      );
    },
    scrollToElement: function(options, ClassName) {
      const el = this.$el.getElementsByClassName(ClassName)[0];

      if (el) {
        el.scrollIntoView(options);
      }
    },
  },
  computed: {
    SelectedProjects: function() {
      return this.projects.filter((p) => {
        return (
          this.currentFilter === p.category || this.currentFilter === "ALLE"
        );
      });
    },
    ShowProjectSocial: function() {
      return this.projects.filter((p) => {
        return p.showMe;
      });
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
.empty_top {
  background-color: white;
  top: 0;
  width: 100%;
  height: 30vh;
}
.container {
  margin-top: 100px;
}
h2 {
  font-family: "Amatic SC", cursive;
  margin-top: 100px;
  font-weight: bold;
  padding: 20px;
}

h4 {
  font-family: "Amatic SC", cursive;
  font-weight: bold;
  text-align: center;
  font-size: 300%;
  /* margin-bottom: -10vh; */
}

.instagram {
  width: 500;
  height: 784;
  border: none;
  overflow: hidden;
}

html,
body {
  margin: 0;
  font-family: "Dawning of a New Day", cursive;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: "Dawning of a New Day", cursive;
  font-size: 30pt;
  font-weight: normal;
}

.project-title {
  font-size: 16pt;
}
.filter {
  font-family: arial;
  padding: 6px 6px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.35s;
}

.filter.active {
  box-shadow: 0px 1px 3px 0px #00000026;
}

.filter:hover {
  background: lightgray;
}

.projects {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.projects-enter {
  transform: scale(0.5) translatey(-80px);
  opacity: 0;
}

.projects-leave-to {
  transform: translatey(30px);
  opacity: 0;
}

.projects-leave-active {
  position: absolute;
  z-index: -1;
}

.circle {
  text-align: center;
  position: absolute;
  bottom: -38px;
  left: 15px;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  /* 	border:1px solid black; */
  display: flex;
  box-shadow: 0px -4px 3px 0px #494d3257;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  /* 	box-shadow:0px -3px 3px #484848a6; */
}

.project {
  transition: all 0.35s ease-in-out;
  margin: 10px;
  box-shadow: 0px 2px 8px lightgrey;
  border-radius: 3px;
  width: 180px;
  height: 200px;
  display: flex;
  flex-direction: column;
  float: left;
  align-items: center;
  cursor: pointer;
}

.project.active {
  border-bottom: 2px solid goldenrod;
}

.project-image-wrapper {
  position: relative;
  width: 150px;
  font-family: "Dawning of a New Day", cursive;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  opacity: 0.09;
  background: linear-gradient(
      to bottom,
      rgba(0, 210, 247, 0.65) 0%,
      rgba(0, 210, 247, 0.64) 1%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top,
      rgba(247, 0, 156, 0.65) 0%,
      rgba(247, 0, 156, 0.64) 1%,
      rgba(0, 0, 0, 0) 100%
    );
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.project-image {
  width: 100%;
  height: 150px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.gallery_container {
  margin-bottom: 150px;
}

.imagegallery {
  margin-top: 40px;
  position: relative;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #ffffff;
  cursor: zoom-in;
}
.overlay:hover {
  opacity: 0.75;
  height: 100%;
  width: 100%;
}
.text {
  color: rgb(0, 0, 0);
  font-family: arial;
  font-style: bold;
  font-size: 20px;
  position: absolute;
  top: 25%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.text-center {
  font-family: arial;
}

.col-lg {
  margin-bottom: 5%;
}
</style>
